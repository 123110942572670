@import '../../styles/variables';
@import '../../styles/mixins';

.artwork_upload__details {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 386px; }

.details__item {
  position: relative;
  & > label {
    position: absolute;
    top: 0;
    left: 0;
    @include font_size_16_b;
    letter-spacing: 0.02em;
    color: $color_purple_main; }
  & > .label_required::after {
    content: '*';
    color: $color_red; }
  & > .artwork_upload__details_holder:focus + label {
    color: $color_purple_accent; } }

.artwork_upload__details_holder {
  margin-top: 34px;
  margin-bottom: 32px;
  box-sizing: border-box;
  width: 386px;
  height: 58px;
  border-radius: 20px;
  border: 2px solid transparent;
  background-color: $color_gray_back;
  outline: none;
  padding: 18px 28px;
  @include font_size_16_b;
  letter-spacing: 0.02em;
  color: $color_black;
  &:focus {
    border: 2px solid $color_purple_accent; }
  &::placeholder {
    color: $color_gray_main; } }

input:-internal-autofill-selected {
  border-color: $color_purple_light;
  background-color: $color_white!important; }

.details_text {
  resize: none;
  height: 150px; }

.not_active {
  background-color: $color_gray_light; }

.artwork_upload__details_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }
