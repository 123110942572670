@import-normalize;

@font-face {
  font-family: Gilroy;
  src: url(./fonts/Gilroy-Regular.eot) format("eot"),
      url(./fonts/Gilroy-Regular.woff2) format("woff2"),
      url(./fonts/Gilroy-Regular.woff) format("woff"),
      url(./fonts/Gilroy-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url(./fonts/Gilroy-Bold.eot) format("eot"),
      url(./fonts/Gilroy-Bold.woff2) format("woff2"),
      url(./fonts/Gilroy-Bold.woff) format("woff"),
      url(./fonts/Gilroy-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url(./fonts/Gilroy-Semibold.eot) format("eot"),
      url(./fonts/Gilroy-Semibold.woff2) format("woff2"),
      url(./fonts/Gilroy-Semibold.woff) format("woff"),
      url(./fonts/Gilroy-Semibold.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

* {
  font-family: Gilroy, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
}

html, body, button, #root {
  height: 100%;
  color: #0E1039;
}

body, #root {
  display: flex;
  flex-direction: column;
}
.main {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

a {
  text-decoration: none;
  color: #7577B1;
}

a:hover {
  text-decoration: none;
  color: #706FF6;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
