@import '../../styles/variables';
@import '../../styles/mixins';

.container.about__container {
  margin-top: 54px;
  padding: 20px;
  & > .legal_page__header {
    margin-bottom: 35px; } }

.about__article {
  margin-bottom: 50px; }

.article__paragrafs_container {
  margin-top: 20px; }

.article_description__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.about__article_paragraf__container {
  box-sizing: border-box;
  min-width: 45%;
  flex: 1;
  &:nth-child(2n+1) {
    margin-right: 30px; }
  &:not(:first-child):not(:nth-child(2)) {
    margin-top: 20px; } }

.h2_title.about__article_title {
  @include font_size_30_b;
  text-transform: uppercase;
  margin: 0; }

.about__article_paragraf {
  margin-bottom: 5px; }

.h3_title.article__subtitle {
  @include font_size_22_b;
  margin-bottom: 10px; }

.about__list {
  padding-left: 20px;
  & > li {
    margin: 0;
    padding: 0; } }

.about__article.about__contacts {
  margin-top: 120px; }
