@import '../../styles/variables';
@import '../../styles/mixins';

button {
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color_white;
  border: none;
  font-size: 18px; }

.button__big {
  width: 386px;
  height: 54px;
  background-color: $color_black;
  border-radius: 16px;
  @include font_size_16_b;
  text-align: center;
  letter-spacing: 0.02em;
  color: $color_white; }

.button__connect {
  background-color: $color_black;
  color: $color_white;
  font-weight: bold;
  letter-spacing: 0.02em;
  border-radius: 20px;
  width: 100px;
  height: 42px; }

.button__connect:hover {
  text-decoration: none; }

.greeting__join_btn {
  width: 212px;
  height: 60px;
  border-radius: 104px;
  color: #fff;
  background-color: #000;
  font-size: 22px;
  line-height: 60px;
  text-transform: uppercase; }

.greeting__join_btn:hover {
  background: rgb(146, 146, 146); }

.greeting__join_btn:active {
  background: #727574;
  box-shadow: 0 3px #565756 inset; }

.button__close {
  position: absolute;
  height: auto;
  top: 30px;
  right: 30px; }

.button__close  > svg {
  fill: $color_gray_light; }

.button__upload {
  padding: 9px 24px;
  background-color: $color_purple_accent;
  border-radius: 12px;
  @include font_size_18_b;
  text-align: center;
  letter-spacing: 0.02em;
  color: $color_white;
  margin-right: 24px; }

.button__copy {
  padding: 9px 24px;
  border-radius: 12px;
  background-color: $color_green;
  color: $color_white;
  @include font_size_16_b; }
