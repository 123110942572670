@import '../../styles/variables';
@import '../../styles/mixins';

.tooltip {
  position: absolute;
  top: 0;
  right: -35px;
  height: 100%;
  display: flex;
  align-items: center;
  @include transition;
  & > svg {
    fill: none;
    @include transition;
    & > path {
      @include transition;
      stroke: $color_black; }
    & > path:last-child {
      @include transition;
      fill: $color_black; } }
  &:hover > svg > path {
    stroke: $color_purple_accent; }
  &:hover > svg > path:last-child {
    fill: $color_purple_accent; } }

.tooltip_text {
  position: absolute;
  top: 70%;
  left: -8px;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  visibility: hidden;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 185px;
  background-color: transparent;
  color: transparent;
  border-radius: 8px;
  padding: 8px 16px;
  box-sizing: border-box;
  @include transition; }

.tooltip:hover > .tooltip_text {
  visibility: visible;
  background-color: #F6F6FE;
  color: $color_black;
  &::before {
    border-color: transparent transparent #F6F6FE transparent; } }

.tooltip_text::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: transparent;
  top: -16px;
  left: 10px;
  z-index: 100;
  @include transition; }
