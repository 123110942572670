@import '../../styles/variables';
@import '../../styles/mixins';

.auction_timer > .timer_dots {
  animation: timer 1s infinite linear; }

@keyframes timer {
  from {
    color: $color_white; }
  to {
    color:  $color_black; } }
