@import '../../styles/variables';
@import '../../styles/mixins';

.modal__wallet {
  width: 498px;
  padding: 56px 56px; }

.modal__wallets_container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.wallet__link {
  color: $color_purple_accent;
  @include font_size_16_b;
  letter-spacing: 0.02em; }

.modal__wallet_accept {
  display: flex;
  flex-direction: row; }

.wallet__button.active {
  background-color: $color_black; }

.wallet__button {
  width: 386px;
  height: 54px;
  background-color: $color_gray_light;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin: 4px 0px;
  cursor: pointer;
  font-size: 18px;
  padding: 0px 31px; }

.wallet__title {
  color: $color_white;
  @include font_size_16_b;
  text-align: center;
  letter-spacing: 0.02em; }

.modal__wallet_accept {
  margin-top: 32px; }

.modal__wallet_accept > label > span > .wallet__link {
  letter-spacing: 0; }

.wallet_modal__warning {
  width: 100%;
  margin-top: 32px;
  padding: 8px 0;
  background-color: $color_red_light;
  border-radius: 10px;
  @include font_size_16_b;
  text-align: center;
  color: $color_red; }
