@import '../../styles/variables';
@import '../../styles/mixins';

.bids__info_center {
  margin: 156px auto !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%; }

.bids__title {
  color: $color_gray_main;
  margin-left: 0; }

.bids__description {
  margin-top: 24px;
  margin-bottom: 56px;
  width: 417px;
  @include font_size_22;
  text-align: center;
  color: $color_gray_main; }

.user_bids__section {
  margin-top: 112px !important; }

.user_bids__container {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 32px !important;
  background-color: $color_gray_back;
  border-radius: 30px;
  width: 100%; }
