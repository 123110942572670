.single_art_card__image.skeleton {
  width: 387px;
  height: 340px;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px; }

.single_art_card__title.skeleton {
  height: 22px;
  width: 170px; }

.single_art_card__author.skeleton {
  margin-top: 2px;
  height: 19px;
  width: 150px; }

.single_art_card__price__container.skeleton {
  height: 44px;
  width: 170px; }

.place_a_bid__button.skeleton {
  height: 44px;
  width: 110px; }

.skeleton {
  animation: skeleton 2s infinite ease-out; }

@keyframes skeleton {
  from {
    background-color: #fff; }
  to {
    background-color:  rgba(14, 16, 57, 0.1); } }
