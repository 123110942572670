@import '../../styles/variables';
@import '../../styles/mixins';

.single_artwork__top_info_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 56px 32px 0 32px; }

.single_artwork__image_container {
  position: relative;
  width: fit-content;
  font-size: 0; }

.single_artwork__image {
  width: 692px;
  height: 607px;
  border-radius: 20px;
  object-fit: cover; }

.single_artwork__image_button_open {
  position: absolute;
  top: 32px;
  right: -27px;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-color: $color_black;
  > svg {
    fill: $color_white; } }

.single_artwork__info {
  min-width: 386px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.single_artwork__title {
  max-width: 386px;
  @include font_size_30_b;
  color: $color_black; }

.single_artwork__description {
  max-width: 386px;
  @include font_size_18;
  letter-spacing: 0.02em;
  color: $color_black; }

.single_artwork__ownership {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px; }

.single_artwork__ownership.owner > .skeleton {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 16px;
  background-color: $color_purple_main;
  animation: bid_processing__img 2s infinite linear; }

.ownership__account_img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 16px; }

.ownership_info {
  display: flex;
  flex-direction: column; }

.ownership_info__status {
  @include font_size_22;
  color: $color_gray_main; }

.ownership_info__name {
  @include font_size_18_b;
  color: $color_purple_main;
  & > a {
    @include font_size_18_b; }
  & > .skeleton {
    animation: bid_processing 2s infinite linear; } }

.single_artwork__bidding {
  position: relative;
  width: fit-content; }

.tooltip_text.button__page_tooltip {
  top: 120%;
  left: -157px;
  &::before {
    right: 10px;
    left: auto; } }


.single_artwork__bidding > .button__big.inactive {
  background-color: $color_gray_light;
  &.active {
    background-color: $color_black; } }

.single_artwork__history_container {
  margin: 112px 32px 0 32px;
  min-height: 200px;
  & > .tabs_navigation__list > .tabs_navigation__item > .tabs_navigation__button {
    @include font_size_30_b;
    border-bottom: 3px solid transparent;
    &.active {
      border-bottom: 3px solid $color_purple_accent; } }
  & > .tabs_navigation__list > .tabs_navigation__item:first-child {
    margin-left: 0; } }

.single_artwork__history {
  margin-top: 32px; }

.single_artwork__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; }

.no_auction {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include font_size_22;
  color: $color_gray_main; }

.single_artwork_auction_timer {
  @include font_size_22_b;
  margin: 32px 0;
  & > .auction_timer {
    @include font_size_22_b; } }

.single_artwork__present_bidding_container {
  // margin-top: 60px
  margin-bottom: 32px; }

.single_artwork__present_bidding_title {
  @include font_size_16_b; }

.single_artwork__user_bid_container {
  margin-bottom: 32px; }

.single_artwork__user_bid, .single_artwork__minimum_bid {
  color: $color_gray_main; }

.single_artwork__user_bid {
  @include font_size_22_b;
  & > .user_bid {
    @include font_size_22_b;
    color: $color_purple_accent;
    &.skeleton {
      animation: bid_processing 2s infinite linear; } } }

.single_artwork__minimum_bid {
  @include font_size_30_b;
  & > .minimum_bid {
    @include font_size_30_b;
    color: $color_black;
    &.skeleton {
      animation: bid_processing 2s infinite linear; } } }

.highest_bidder {
  background-color: $color_green; }

.single_artwork__approve_nft_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 112px; }

.single_artwork__approve_nft_message {
  & > .auction_timer {
    display: inline-block;
    width: 100px;
    margin-left: 10px; } }


.resale_approve__in_progress.skeleton {
  display: inline-block;
  height: 118px;
  width: 100%;
  text-align: center;
  @include font_size_22_b;
  line-height: 118px;
  color: $color_purple_accent;
  animation: bid_processing 2s infinite linear; }

@media ( max-width: 1180px ) {
  .single_artwork__top_info_container {
    flex-direction: column; }

  .single_artwork__info {
    margin-top: 38px; } }

@keyframes bid_processing {
  from {
    color: $color_purple_light; }
  to {
    color:  $color_gray_main; } }

@keyframes bid_processing__img {
  from {
    background-color: $color_purple_light; }
  to {
    background-color:  $color_gray_main; } }
