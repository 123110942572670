@import '../../styles/variables';
@import '../../styles/mixins';

.terms__data {
  flex-direction: row; }

.terms__navigation {
  position: sticky;
  top: 15px;
  left: 15px;
  width: fit-content;
  height: fit-content;
  border-radius: 20px;
  background-color: $color_gray_back;
  box-sizing: border-box; }

.terms__navigation_list {
  margin: 22px 30px;
  padding-right: 20px;
  width: 245px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  overflow-y: scroll; }

.terms__navigation_list_item {
  margin: 8px 0; }

.terms__navigation_list_item > .terms__nav_link {
  @include font_size_16_sb;
  color: $color_gray_dark;
  @include transition;
  &:hover {
    @include font_size_16_sb;
    color: $color_black; } }

.terms__nav_link.active {
  @include font_size_16_b;
  color: $color_black; }

.terms__nav_link__subsections_list {
  margin-left: 15px; }

.terms__sections {
  margin-left: 70px; }

.terms__single_term_container {
  margin-bottom: 50px; }

.terms__term_title {
  @include font_size_30_b;
  margin-bottom: 20px; }

.terms__term_description {
  @include font_size_16;
  color: $color_gray_dark; }

.paragraf__part > b {
  @include font_size_16_b; }

.term__subsection {
  & > .terms__term_title {
    @include font_size_22_b;
    margin-bottom: 10px; } }

.terms_paragraf__list {
  list-style-type: disc;
  margin-left: 40px;
  & > li {
    @include font_size_16; }
  & > li > b {
    @include font_size_16_b; } }
