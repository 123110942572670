@import '../../styles/variables';
@import '../../styles/mixins';

.user_artworks__container {
  background-color: $color_gray_back;
  border-radius: 30px;
  margin-top: 15px;
  padding: 32px 32px 15px 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap; }

.user_artworks__container.no_arts {
  background-color: $color_white;
  justify-content: center; }

.user_artworks__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }

.user_collection__title {
  margin-left: 20px;
  @include font_size_36_b;
  color: $color_black; }

.user_collection__navigation.not_allowed > h3,
.user_collection__navigation.not_allowed > ul > li > .tabs_navigation__button, {
  color: $color_gray_main;
  border: none; }
.user_collection__navigation.not_allowed > ul > li > .tabs_navigation__button.active {
  border: none; }

.user_collection__navigation {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start; }

.user_artworks__art {
  margin-right: 17px;
  margin-bottom: 17px; }

.user_artworks__art:nth-child(3n) {
  margin-right: 0; }

.user_artworks__art > .single_art_card__container {
  margin: 0; }

.art__sold {
  position: relative; }

.art__sold:after {
  content: 'Sold';
  color: $color_white;
  border: 2px solid $color_white;
  border-radius: 10px;
  box-sizing: border-box;
  height: 35px;
  padding: 5px 16px;
  @include font_size_18_b;
  line-height: 18px;
  position: absolute;
  z-index: 999;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }

.art__on_sale {
  position: relative; }

.art__on_sale:after {
  content: 'On sale';
  color: $color_white;
  border: 2px solid $color_white;
  border-radius: 10px;
  box-sizing: border-box;
  height: 35px;
  padding: 5px 16px;
  @include font_size_18_b;
  line-height: 18px;
  position: absolute;
  z-index: 999;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }

.art__not_on_sale {
  position: relative; }

.art__not_on_sale:after {
  content: 'Not on sale';
  color: $color_white;
  border: 2px solid $color_white;
  border-radius: 10px;
  box-sizing: border-box;
  height: 35px;
  padding: 5px 16px;
  @include font_size_18_b;
  line-height: 18px;
  position: absolute;
  z-index: 999;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }

.user_artworks__no_arts {
  margin: 112px 0;
  display: flex;
  flex-direction: column;
  align-items: center; }

.no_arts__plain__description {
  margin-bottom: 24px;
  min-width: 417px;
  @include font_size_22;
  text-align: center;
  color: $color_gray_main; }

@media (max-width: 1260px) {
  .user_artworks__container {
    justify-content: center; }
  .user_artworks__art {
    margin-right: 5px;
    &:nth-child(2n) {
      margin-right: 0; }
    &:nth-child(3n) {
      margin-right: 5px; } } }

@media (max-width: 740px) {
  .user_artworks__art {
    margin-right: 0;
    &:nth-child(2n) {
      margin-right: 0; }
    &:nth-child(3n) {
      margin-right: 0; } }

  .user_artworks__header {
    align-items: baseline; }

  .user_collection__navigation {
    align-items: center; } }
