@import '../../styles/variables';
@import '../../styles/mixins';

.tabs_navigation__list {
  display: flex; }

.tabs_navigation__item {
  margin-left: 52px; }

.tabs_navigation__button {
  @include font_size_18_b;
  letter-spacing: 0.02em;
  color: $color_black;
  border-bottom: 2px solid transparent;
  @include transition;
  &.active {
    color: $color_purple_accent;
    border-bottom: 2px solid $color_purple_accent; }
  &:hover {
    color: $color_purple_accent; } }

@media (max-width: 740px) {
  .tabs_navigation__list {
    flex-direction: column; }

  .tabs_navigation__item {
    margin-bottom: 5px; } }
