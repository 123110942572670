@import '../../styles/variables';
@import '../../styles/mixins';

.upload__back_to_account {
  position: absolute;
  top: 10px;
  left: 24px;
  @include font_size_18;
  letter-spacing: 0.02em;
  color: $color_purple_main;
  & > a > svg {
    fill: $color_purple_main; }
  & > a:hover > svg {
    fill: $color_purple_accent; } }

.upload_section__steps {
  margin-top: 24px;
  margin-bottom: 56px;
  @include font_size_18_b;
  letter-spacing: 0.02em;
  color: $color_black; }

.artwork_upload__container {
  position: relative;
  max-width: 996px;
  justify-content: center;
  align-items: center;
  margin-bottom: 44px !important; }

.cloudlink_input {
  width: 650px; }

.artwork_upload__loader {
  width: 996px;
  height: 275px;
  border: 2px dashed #E7E8FF;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  cursor: pointer; }

.artwork_upload__loader.image {
  height: 342px; }

.artwork_upload__loader.document {
  height: 164px; }

input[type=file] {
  display: none; }

.button__browze {
  display: flex;
  align-items: center;
  justify-content: center; }

.artwork_upload__title,
.artwork_upload__description {
  @include font_size_18;
  letter-spacing: 0.02em;
  color: $color_gray_main; }

.artwork_upload__title {
  margin-bottom: 36px; }

.artwork_upload__description {
  margin-top: 76px; }

.artwork_upload__artwork_data {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 386px; }

.artwork_upload__preview {
  width: 386px;
  height: 342px;
  object-fit: cover;
  border-radius: 20px;
  box-sizing: border-box; }

.button_delete {
  font-size: 16px;
  line-height: 26px;
  color: $color_purple_main;
  & > svg {
    fill: $color_purple_main;
    margin-left: 9px; } }

.button_delete__image {
  position: absolute;
  top: 26px;
  right: 26px;
  color: $color_white;
  height: auto;
  background-color: transparent;
  margin-right: 5px;
  & > svg {
    fill: $color_white; } }

.spinner__holder {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(2255,255,255, 0.9);
  z-index: 100;
  display: flex;
  align-items: center;
  & > svg {
    z-index: 1000; }
  &.image__spinner {
    width: 105%;
    height: 105%;
    justify-content: center;
    top: -5px;
    left: -5px;
    border-radius: 20px;
    & > svg {
      width: 56px;
      height: 56px; } }
  &.doc__spinner {
    justify-content: flex-end;
    & > svg {
      width: 35px;
      height: 35px; } } }

.artwork_upload__artwork_document {
  display: flex;
  align-items: center; }

.artwork_document {
  margin-left: 25px;
  @include font_size_18;
  color: $color_black;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis; }

.artwork_data__list {
  margin-left: 20px; }

.artwork_data__hash {
  margin-top: 10px;
  box-sizing: border-box; }

.artwork_upload__buttons {
  display: flex;
  flex-direction: row;
  box-sizing: border-box; }

.artwork_upload__button_next {
  background-color: $color_gray_light; }

.artwork_upload__button_next.active {
  background-color: $color_black; }

@media ( max-width: 996px ) {
  .artwork_upload__loader {
    width: 760px; } }


@media ( max-width: 760px ) {
  .upload__back_to_account {
    top: -40px; }
  .artwork_upload__loader {
    width: 500px; } }
