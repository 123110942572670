@import '../../styles/variables';
@import '../../styles/mixins';

.footer {
  height: 120px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 30px;
  padding-bottom: 30px; }

.footer__container {
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #F6F6FE;
  padding: 0 30px;
  border-radius: 30px; }

.footer__container > ul {
  height: 100%; }

.footer__email {
  margin: 0 20px 0 0;
  padding: 0; }

.footer__main_nav {
  display: flex;
  align-items: center; }

.footer_nav__item {
  margin-right: 50px;
  letter-spacing: 0.02em; }

.footer_nav__link {
  color: $color_purple_main;
  @include transition; }


.footer_nav__link::after {
  display: block;
  content: attr(data-text);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden; }

.footer_nav__link:hover {
  font-weight: bold;
  color: $color_purple_accent; }

.footer__social {
  display: flex;
  align-items: center; }

.social_nav__item {
  margin-left: 24px; }

.social_nav__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 16px;
  box-sizing: border-box;
  @include transition; }

.social_nav__link > svg {
  fill: $color_purple_main;
  @include transition; }

.social_nav__link:hover {
  background: $color_purple_accent; }

.social_nav__link:hover > svg {
  fill: #fff; }
