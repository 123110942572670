.modal__container.modal__image {
  background-color: transparent;
  & > header {
    display: none; } }

.single_artwork__big_image_container {
  display: flex;
  justify-content: center;
  background-color: transparent;
  height: fit-content;
  font-size: 0;
  width: 1052px; }

.single_artwork__big_image + .single_artwork__image_button_open {
  right: 0; }

.single_artwork__big_image {
  background-color: transparent;
  max-width: 996px;
  max-height: 100vh;
  object-fit: contain;
  border-radius: 20px; }
