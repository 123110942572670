@import '../../styles/variables';
@import '../../styles/mixins';

.container.legal_page__container {
  position: relative;
  margin-top: 120px;
  align-items: center; }

.legal_page__section_container {
  width: 100%; }

.legal__link {
  @include font_size_16_sb; }

.legal__text_item {
  @include font_size_16_sb;
  & > b {
    @include font_size_16_b; }
  & > u {
    @include font_size_16_sb; } }

.legal_page__header {
  box-sizing: border-box;
  border-radius: 20px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $color_gray_back;
  margin-bottom: 70px;
  min-height: 146px; }

.legal_page__heading {
  @include font_size_36_b;
  text-transform: capitalize; }

.legal_page__update {
  @include font_size_16;
  color: #B1B1B1; }

.legal_page__data {
  position: relative;
  display: flex; }

.legal__section_item {
  margin: 30px 0;
  padding: 0;
  @include font_size_30_b; }

.legal__section_item_header {
  @include font_size_30_b;
  margin-left: 10px;
  margin-bottom: 30px; }

.legal__section_item_description {
  display: flex;
  flex-direction: column;
  margin-left: -33px; }

.legal__sections_list {
  margin: 0;
  padding-left: 33px; }

.legal__paragraf:not(:last-child),
.legal__paragraf > .legal__text_item:not(:last-child) {
  margin-bottom: 10px; }

.ordered_list {
  list-style: lower-alpha;
  list-style-position: inside;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  & > li:not(:last-child) {
    margin-bottom: 10px; }
  & > li > .legal__text_item:not(:last-child) {
    margin-bottom: 10px; }
  & > li > div:first-child {
    display: inline; } }

.unordered_list {
  list-style: disc;
  list-style-position: inside;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  & > li:not(:last-child) {
    margin-bottom: 10px; }
  & > li > .legal__text_item:not(:last-child) {
    margin-bottom: 10px; }
  & > li > div:first-child {
    display: inline; } }
