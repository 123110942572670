@import '../../styles/variables';
@import '../../styles/mixins';

.styled_checkbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px; }

.styled_checkbox__label {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include font_size_16;
  line-height: 26px;
  letter-spacing: 0;
  cursor: pointer;
  & > svg {
    cursor: pointer; }
  & > span {
    margin-left: 11px;
    font-size: 16px; } }

.styled_checkbox__label.disabled {
  cursor: default;
  & > svg {
    cursor: default; }
  & > svg > path {
    fill: $color_gray_light; }
  & > span {
    color: $color_gray_light; } }

.styled_checkbox__checkbox {
  opacity: 0;
  height: 0;
  width: 0; }

.styled_checkbox__checkbox[type="checkbox" i]:disabled + span {
  color: $color_gray_light; }
