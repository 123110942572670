.home__top_section {
  background-color: #F2F2F2;
  height: 500px; }

.home__container {
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px; }

.home__description {
  font-size: 22px;
  line-height: 36px;
  color: #242424;
  margin: 39px 0 19px 0; }

.home__text {
  font-size: 18px;
  line-height: 30px;
  color: #828282;
  margin: 0 0 57px 0; }
