@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.single_artwork__info_tab_item {
  margin-bottom: 32px; }

.single_artwork__info_tab_time {
  margin-bottom: 8px;
  height: 22px;
  @include font_size_16;
  color: $color_gray_main;
  & > .skeleton {
    display: inline-block;
    width: 300px;
    height: 22px; }
  & > a {
    display: flex;
    align-items: center;
    width: fit-content;
    @include font_size_16;
    @include transition;
    color: $color_gray_main;
    &:hover {
      color: $color_purple_accent;
      & > svg {
        fill: $color_purple_accent; } }
    & > svg {
      margin-left: 10px;
      height: 16px;
      fill: $color_gray_main; } } }

.single_artwork__info_tab_address {
  @include font_size_16_b;
  & > .skeleton {
    display: inline-block;
    width: 350px;
    height: 22px; } }

.info_tab_item__link {
  @include font_size_16_b;
  color: $color_purple_main; }
