@import '../../styles/variables';
@import '../../styles/mixins';

.header {
	margin-top: 30px;
	min-height: 90px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: #FBFBFB;
	border-radius: 20px;
	width: 100%;
	padding: 0 32px;
	box-sizing: border-box; }

.header__list {
	display: flex;
	height: 100%;
	align-items: center; }

.header__list_item {
	margin: 0;
	margin-right: 52px;
	box-sizing: border-box;
	font-size: 0;
	position: relative; }

.header__list_item:last-child {
	margin-right: 0px; }

.list_item__link {
	text-decoration: none;
	letter-spacing: 0.02em;
	color: $color_purple_main;
	@include transition;
	&::after {
    display: block;
    content: attr(data-text);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden; } }

.list_item__link:hover {
	text-decoration: none;
	font-weight: bold;
	color: $color_purple_accent;
	& > .list_item__bids {
		background-color: $color_purple_accent; } }

.list_item__bids {
	position: absolute;
	top: -15px;
	right: -15px;
	font-size: 14px;
	font-weight: normal;
	color: $color_white;
	background-color: $color_black;
	display: flex;
	min-width: 21px;
	height: 21px;
	border-radius: 50%;
	text-align: center;
	align-items: center;
	justify-content: center;
	@include transition; }

.list_item__link_selected {
	font-weight: bold;
	color: $color_black; }

.list_item__link_selected:hover {
	color: $color_purple_accent; }

.account__link {
	display: flex;
	flex-direction: row;
	align-items: center; }

.header__account_title {
	letter-spacing: 0.02em;
	color: $color_gray_main;
	font-weight: bold; }

.header__account_img {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	margin-left: 20px; }
