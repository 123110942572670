@import '../../styles/variables';
@import '../../styles/mixins';

.plug__page {
  justify-content: center;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold; }

.error_page__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 112px !important; }

.error_page__404 {
  font-size: 200px;
  line-height: 242px;
  font-weight: bold;
  color: $color_gray_back; }

.error_page__description {
  margin-top: 8px;
  margin-bottom: 56px;
  max-width: 305px;
  @include font_size_18;
  text-align: center;
  letter-spacing: 0.02em;
  color: $color_black; }
