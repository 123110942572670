@mixin font_size_56_b {
  font-size: 56px;
  font-weight: bold;
  line-height: 91px; }

@mixin font_size_48_b {
  font-size: 48px;
  font-weight: bold;
  line-height: 58px; }

@mixin font_size_36_b {
  font-size: 36px;
  font-weight: bold;
  line-height: 44px; }

@mixin font_size_30_b {
  font-size: 30px;
  font-weight: bold;
  line-height: 49px; }

@mixin font_size_22_b {
  font-size: 22px;
  font-weight: bold;
  line-height: 36px; }

@mixin font_size_20_b {
  font-size: 20px;
  font-weight: bold;
  line-height: 32px; }

@mixin font_size_22 {
  font-size: 22px;
  font-weight: normal;
  line-height: 36px; }

@mixin font_size_18_b {
  font-size: 18px;
  font-weight: bold;
  line-height: 25px; }

@mixin font_size_18 {
  font-size: 18px;
  font-weight: normal;
  line-height: 25px; }

@mixin font_size_16_b {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px; }

@mixin font_size_16_sb {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px; }

@mixin font_size_16 {
  font-size: 16px;
  font-weight: normal;
  line-height: 22px; }

@mixin transition {
  transition: all ease-out .3s; }
