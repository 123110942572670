@import '../../styles/variables';
@import '../../styles/mixins';

.faq__container {
  position: relative;
  margin-top: 112px !important;
  align-items: center; }

.faq__section_container {
  width: 793px;
  margin-left: 42px; }

.faq__heading {
  @include font_size_56_b;
  margin-bottom: 40px; }

.faq__section {
  margin-bottom: 70px; }

.faq__section_title {
  @include font_size_30_b;
  margin-bottom: 40px; }

.faq__paragraf {
  position: relative;
  margin-bottom: 10px; }

.faq__paragraf_title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #BCBCBC;
  cursor: pointer;
  transition: border-bottom 300ms; }

.faq__paragraf_title_container.active {
  border-bottom: 1px solid transparent; }

.faq__paragraf_title_container > svg {
  transition: all 300ms; }

.faq__paragraf_title_container.active > svg {
  transform: rotate(90deg); }

.faq__paragraf_title {
  @include font_size_20_b;
  margin: 10px 0; }


.faq__paragraf_description-exit-done {
  & > ul > li {
    @include font_size_16; }
  & > ul > li > b {
    @include font_size_16_b; }
  & > b {
    @include font_size_16_b; } }

.faq__paragraf_description-enter, .faq__paragraf_description-enter-done {
  @include font_size_16;
  opacity: 0;
  height: 0;
  transition: all 300ms; }

.faq__paragraf_description-enter-active {
  @include font_size_16;
  opacity: 0;
  height: fit-content;
  transition: all 300ms; }

.faq__paragraf_description-exit, .faq__paragraf_description-exit-done {
  @include font_size_16;
  color: $color_gray_dark;
  cursor: pointer;
  opacity: 1;
  height: fit-content;
  transition: all 300ms; }

.faq__paragraf_description-exit-active {
  @include font_size_16;
  opacity: 1;
  height: fit-content;
  transition: all 500ms; }

.faq__paragraf_description {
  @include font_size_16;
  color: $color_gray_dark;
  cursor: pointer; }

.faq__contacts_section {
  margin-bottom: 70px; }

.faq__paragraf_title.text {
  font-weight: normal; }

.faq__paragraf_title > a {
  @include font_size_20_b; }
