@import '../../styles/variables';
@import '../../styles/mixins';

.account_header__container {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 112px !important;
  margin-bottom: 32px !important; }

.button__logout {
  margin-right: 32px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: $color_purple_main; }

.account_info__container {
  position: relative;
  &.user__page {
    margin-top: 112px; } }

.account_info__background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  width: 100%;
  height: 200px;
  background: #F6F6FE;
  border-radius: 20px; }

.account_info__data {
  width: 100%;
  padding-top: 119px;
  padding-left: 132px;
  margin-bottom: 174px;
  display: flex;
  flex-direction: row;
  align-items: flex-end; }

.account_info__user_img {
  width: 183px;
  height: 183px;
  background: #F6F6FE;
  border: 10px solid $color_white;
  box-sizing: border-box;
  border-radius: 20px; }

.account_info__user_info {
  margin-left: 24px;
  margin-bottom: 12px; }

.account_info__user_address {
  height: 49px;
  display: flex;
  align-items: center; }

.account_info__title {
  font-weight: bold;
  font-size: 30px;
  margin-right: 24px;
  color: $color_black;
  @include transition; }

.account_info__user_badge {
  height: 36px;
  padding: 0 16px;
  background: $color_green;
  border-radius: 10px;
  @include font_size_18_b;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: center;
  color: $color_white; }

.account_info__user_name {
  @include font_size_18;
  letter-spacing: 0.02em;
  color: $color_gray_main; }
