$color_black: #0E1039;

$color_gray_main: #909090;

$color_gray_dark: #626262;

$color_gray_light: #CFCFD7;

$color_gray_back: #F6F6F6;

$color_purple_accent: #706FF6;

$color_purple_main: #7577B1;

$color_purple_light: #E7E8FF;

$color_white: #fff;

$color_red: #EB4141;

$color_red_light: #FFF1F1;

$color_green: #B3D8D8;

