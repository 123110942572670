@import '../../styles/variables';

.all_arts__section {
  margin-top: 112px !important; }

.button__load_more {
  flex-direction: column;
  color: $color_purple_main;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-top: 100px;
  margin-bottom: 75px; }

.button__load_more > svg {
  fill: $color_purple_main; }

.all__loaded {
  margin: 50px 0;
  text-align: center;
  color: $color_purple_main;
  font-weight: bold; }
