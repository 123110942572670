@import '../../styles/variables';
@import '../../styles/mixins';

.modal_with_artwork {
  margin: 0px 150px; }

.modal_with_artwork__container {
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  background-color: #fff; }


.modal_with_artwork__artwork_card {
  background-color: $color_gray_back;
  padding: 32px;
  box-sizing: border-box;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  & > .single_art_card__container {
    margin: 0; } }

.modal_with_artwork__data_container {
  padding: 92px 72px 0 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > .modal__header > .modal__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > .modal__subtitle {
      margin: 0;
      margin-top: 4px;
      letter-spacing: 0.02em; } } }

.modal_with_artwork__item_with_tooltip {
  position: relative; }

.modal_with_artwork__input_container {
  margin-top: 56px;
  width: 386px; }

.modal_with_artwork__buttons_container > .modal__warning {
  margin: 0; }

.modal_with_artwork__button {
  box-sizing: content-box;
  display: flex;
  & > button {
    margin: 32px 0; } }

.modal_with_artwork__active_button {
  margin: 32px 0; }

.modal_with_artwork___text_with_link {
  margin-top: 24px;
  @include font_size_16;
  color: $color_black;
  letter-spacing: 0.02em;
  & > a {
    @include font_size_16_b;
    color: $color_purple_accent;
    letter-spacing: 0.02em; } }
