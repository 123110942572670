@import './styles/variables';
@import './styles/mixins';

h1, h2, h3, h4, p {
  margin: 0;
  padding: 0; }

.container {
  display: flex;
  flex-direction: column;
  max-width: 1260px;
  margin: 0 auto;
  text-align: left; }

.h1_title {
  font-size: 50px;
  line-height: 58px;
  color: #242424; }

.h2_title {
  @include font_size_48_b;
  margin-left: 30px; }

.h3_title {
  @include font_size_36_b; }

.h4_title {
  @include font_size_22_b; }

.arts_grid__container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 30px;
  background-color: $color_gray_back;
  border-radius: 30px;
  padding: 30px 30px 0 30px; }

.arts_grid__container > .single_art_card__container {
  margin: 0;
  margin-bottom: 30px; }

#root {
  position: relative; }

.letter_container__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end; }

.letter_container__header_label {
  cursor: pointer; }

.paragraf__part {
  @include font_size_16;
  color: $color_gray_dark;
  margin: 8px 0; }

.Toastify__toast--error {
  background: $color_red_light;
  @include font_size_16_b;
  color: $color_red; }

.Toastify__close-button--error {
  color: $color_red; }

.Toastify__progress-bar--error {
  background-color: $color_red; }

.Toastify__toast--success {
  background: $color_green;
  @include font_size_16_b; }

.Toastify__toast--info {
  background: $color_purple_light;
  color: $color_purple_main;
  @include font_size_16_b; }

.Toastify__close-button--info {
  color: $color_purple_main; }

.Toastify__progress-bar--info {
  background-color: $color_purple_main; }
