@import '../../styles/variables';
@import '../../styles/mixins';


.privacy_policy__data {
  flex-direction: column; }

.privacy__subsection_container:not(:last-child),
.privacy__subsection_container > .legal__text_item:not(:last-child) {
  margin-bottom: 10px; }

.privacy__subsection_container > .legal__text_item.privacy__subsection_numbering {
  margin-bottom: 0; }

.legal__text_item.privacy__subsection_numbering + .legal__text_item {
  display: inline; }

.privacy__subsection_subarticle {
  margin-top: 10px; }

.privacy__subsection_numbering {
  display: inline-block;
  margin-right: 10px; }

.privacy__subsection_heading {
  font-weight: bold; }
