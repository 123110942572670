@import '../../styles/variables';
@import '../../styles/mixins';

.single_art_card__container {
  position: relative;
  width: 387px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: $color_white;
  box-sizing: border-box;
  margin: 30px 10px;
  border-radius: 20px; }

.single_art_card__container:nth-child(3n) {
  margin-right: 0; }

.single_art_card__image_gradient {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 138px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display:inline-block {}
  background: linear-gradient(182.33deg, rgba(14, 16, 57, 0.4) 1.95%, rgba(14, 16, 57, 0) 98.05%); }

.single_art_card__timer {
  position: absolute;
  z-index: 999;
  left: 20px;
  top: 20px;
  width: 100%;
  color: $color_white;
  @include font_size_22_b;
  & > span {
    @include font_size_22; }
  & > .single_art_card__timer_container {
    display: inline-block;
    width: 120px;
    &.auction__ended {
      width: 100%; }
    & > span {
      @include font_size_22_b; } } }

.single_art_card__image {
  width: 387px;
  height: 340px;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px; }

.single_art_card__data {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  height: 196px; }

.h4_title.single_art_card__title {
  line-height: 30px; }

.single_art_card__title > a {
  @include font_size_22;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: $color_black;
  font-weight: normal;
  @include transition;
  & > i {
    @include font_size_22;
    line-height: 30px;
    letter-spacing: 0.02em; } }

.single_art_card__title > a:hover {
  color: $color_purple_accent; }

.single_art_card__price {
  @include font_size_22;
  color: $color_gray_main; }

.price__crypto {
  margin-right: 5px;
  color: $color_black; }

.single_art_card__price.price__crypto:first-child {
  font-weight: bold; }

.single_art_card__author {
  color: $color_black;
  @include font_size_18;
  text-decoration: none; }

.single_art_card__author > a {
  color: $color_black;
  @include transition;
  &:hover {
    color: $color_purple_accent; } }

.single_art_card__bid_container {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  min-height: 36px; }

.single_art_card__price__container {
  position: relative; }

.single_art_card__current_bid {
  position: absolute;
  top: -22px;
  left: 0;
  @include font_size_22;
  line-height: 22px;
  color: $color_gray_main;
  width: max-content; }

.place_a_bid__button {
  @include font_size_22_b;
  text-transform: uppercase;
  color: $color_purple_accent; }

@media (max-width: 1040px) {
  .single_art_card__container {
    margin-right: 0; }
  .top_arts__container {
    padding: 0 15px; } }
