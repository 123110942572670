@import '../../styles/variables';
@import '../../styles/mixins';

.main__slider_container {
  margin-top: 112px !important; }

.slider__container {
  box-sizing: border-box;
  margin-top: 30px;
  padding: 0 30px;
  background-color: $color_gray_back;
  border-radius: 30px;
  width: auto; }

.slick-arrow {
  background: $color_purple_main;
  border-radius: 20px;
  width: 80px;
  height: 56px;
  z-index: 10;
  transform: translate(0, -50%);
  opacity: 1;
  @include transition;
  display: flex;
  justify-content: center;
  align-items: center; }

.slick-arrow > svg {
  fill: $color_white; }

.slick-prev:before, .slick-next:before {
  content: ''; }

.slick-prev .slick-next {
  background: $color_purple_main; }

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  background: $color_purple_main; }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: 0.5; }

.slick-arrow.slick-prev.slick-disabled, .slick-arrow.slick-next.slick-disabled {
  opacity: 0;
  @include transition; }
