@import '../../styles/variables';
@import '../../styles/mixins';

.modal__place_a_bid {
  width: 1220px;
  background-color: transparent;
  & > .modal__header > .button__close {
    right: 135px; } }

.place_a_bid_modal__container {
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  background-color: $color_white; }

.place_a_bid__artwork_card {
  background-color: $color_gray_back;
  padding: 32px;
  box-sizing: border-box;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  & > .single_art_card__container {
    margin: 0; } }

.place_a_bid__bid_form {
  padding: 92px 72px 0 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > .modal__header > .modal__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > .modal__subtitle {
      margin: 0;
      margin-top: 4px;
      letter-spacing: 0.02em; } } }

.place_a_bid__header_min_amount {
  @include font_size_16_b;
  letter-spacing: 0.02em; }

.place_a_bid__input_container {
  margin-top: 56px;
  width: 386px; }

.place_a_bid__input_label {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.place_a_bid__input_title {
  @include font_size_16_b;
  letter-spacing: 0.02em;
  color: $color_purple_main;
  width: 100px; }

.place_a_bid__input_available_text {
  @include font_size_18;
  letter-spacing: 0.02em;
  color: $color_gray_main;
  text-align: right; }

.place_a_bid__input_available_balance {
  @include font_size_18_b;
  letter-spacing: 0.02em;
  color: $color_black; }

.place_a_bid_input {
  width: 386px;
  height: 58px;
  border-radius: 20px;
  outline: none;
  border: 2px solid transparent;
  background-color: $color_gray_back;
  padding: 18px 28px;
  box-sizing: border-box;
  color: $color_black;
  @include font_size_16_b;
  letter-spacing: 0.02em;
  &::placeholder {
    color: $color_gray_main; }
  &:focus {
    border-color: $color_purple_light;
    background-color: $color_white; } }

.place_a_bid_input::-webkit-outer-spin-button,
.place_a_bid_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.place_a_bid_input {
  -moz-appearance: textfield; }

.place_a_bid__buttons_container {
  margin-top: 56px; }

.button_with_tooltip {
  position: relative; }

.button_with_tooltip > .skeleton {
  animation: approval_processing 2s infinite linear; }

.place_a_bid__buttons_container > .single_artwork__bidding {
  margin-bottom: 12px; }

.place_a_bid__tooltip {
  position: absolute;
  top: 0;
  right: -35px;
  height: 100%;
  display: flex;
  align-items: center;
  @include transition;
  & > svg {
    fill: none;
    @include transition;
    & > path {
      @include transition;
      stroke: $color_black; }
    & > path:last-child {
      @include transition;
      fill: $color_black; } }
  &:hover > svg > path {
    stroke: $color_purple_accent; }
  &:hover > svg > path:last-child {
    fill: $color_purple_accent; } }

.place_a_bid__tooltip_text {
  position: absolute;
  top: 52px;
  left: -8px;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  visibility: hidden;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 185px;
  background-color: transparent;
  color: transparent;
  border-radius: 8px;
  padding: 8px 16px;
  box-sizing: border-box;
  @include transition; }

.place_a_bid__tooltip:hover > .place_a_bid__tooltip_text {
  visibility: visible;
  background-color: #F6F6FE;
  color: $color_black;
  &::before {
    border-color: transparent transparent #F6F6FE transparent; } }

.place_a_bid__tooltip_text::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: transparent;
  top: -16px;
  left: 10px;
  z-index: 100;
  @include transition; }

.place_a_bid__how {
  @include font_size_16;
  color: $color_black;
  letter-spacing: 0.02em;
  & > a {
    @include font_size_16_b;
    color: $color_purple_accent;
    letter-spacing: 0.02em; } }

@keyframes approval_processing {
  from {
    background-color: $color_black; }
  to {
    background-color: $color_purple_light; } }
