@import '../../styles/variables';
@import '../../styles/mixins';

.pass_kyc__container,
.get_invite__container,
.upload__container {
  position: relative;
  align-items: center;
  margin-top: 112px !important; }

.container.pass_kyc__container {
  margin-bottom: 112px; }

.letter__description {
  margin-top: 24px;
  margin-bottom: 55px;
  max-width: 554px;
  @include font_size_18;
  text-align: center;
  letter-spacing: 0.02em;
  color: $color_black;
  & > a {
    font-weight: bold;
    color: $color_purple_accent; } }

.letter__message_text_container {
  box-sizing: border-box;
  margin-bottom: 54px;
  width: 794px; }

.message_text__label {
  @include font_size_16_b;
  color: $color_purple_accent; }

.message_text__area {
  min-height: 452px;
  border: 2px solid $color_purple_accent;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 8px 0;
  padding: 16px 28px;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: $color_black; }

.message_text__header {
  width: 100%;
  min-height: 75px;
  border: none;
  color: $color_gray_main;
  caret-color: #706FF6;
  resize: none;
  outline: none; }

.letter__moderation_info {
  box-sizing: border-box;
  padding: 10px 54px;
  background: $color_green;
  border-radius: 10px;
  @include font_size_16_b;
  color: $color_white;
  text-align: center; }

.button__send_letter {
  margin-bottom: 112px; }
