@import '../../styles/variables';
@import '../../styles/mixins';

.modal__get_invite {
  width: 498px;
  padding: 56px 56px;
  margin: 0px 150px; }

.modal__wallets_container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.invite__link {
  color: $color_purple_accent;
  @include font_size_16_b;
  letter-spacing: 0.02em; }

.get_invite__item {
  position: relative; }

.get_invite_modal__warning {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 32px;
  padding: 8px;
  background-color: $color_red_light;
  border-radius: 10px;
  @include font_size_16_b;
  text-align: center;
  color: $color_red; }
