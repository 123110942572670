@import '../../styles/variables';
@import '../../styles/mixins';

.modal__wallets_container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.resale_artwork__item {
  position: relative; }
