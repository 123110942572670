@import '../../styles/variables';
@import '../../styles/mixins';

.modal__container {
  position: relative;
  background-color: $color_white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 20px; }

.modal__header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: $color_black; }

.modal__title {
  text-align: center; }

.modal__heading {
  @include font_size_30_b; }

.modal__subtitle {
  @include font_size_16;
  letter-spacing: 0.02em;
  margin: 32px 0;
  width: 340px; }

.modal__warning {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 32px;
  padding: 8px;
  background-color: $color_red_light;
  border-radius: 10px;
  @include font_size_16_b;
  text-align: center;
  color: $color_red; }
